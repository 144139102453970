<template>
<v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none subtitle-2">
            © &#x2117; Lava Productions, LLC dba Lava for Good™ Podcasts. Lava, Lava for Good™, and the Lava logo are trademarks of Lava Media, LLC.
          </div>
        </v-col>
        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          <router-link class="white--text mr-6"  to="/privacy">
            Privacy Policy
          </router-link>
          <router-link class="white--text"  to="/terms">
            Terms & Conditions
          </router-link>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
  export default {
    name: 'Links',

    data: () => ({
    }),
  }
</script>
