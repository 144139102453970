<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>
<script>
  // Styles
  import '@/styles/overrides.sass'

  export default {
    name: 'App',
    metaInfo: {
      title: 'Home',
      titleTemplate: '%s | Lava for Good',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Unparalleled access and insights into the heart of social justice in America'},
        { vmid: 'og:title', property: 'og:title', content: 'Home'},
        { vmid: 'og:site_name', property: 'og:site_name', content: 'Lava for Good'},
        { vmid: 'og:description', property: 'og:description', content: 'Unparalleled access and insights into the heart of social justice in America'},
        { vmid: 'og:image', property: 'og:image', content: 'https://lavaforgood.com/images/sticker_logo.png'},
        { vmid: 'og:image:secure_url', property: 'og:image:secure_url', content: 'https://lavaforgood.com/images/sticker_logo.png'},
        { vmid: 'og:image:width', property: 'og:image:width', content: '1167'},
        { vmid: 'og:image:height', property: 'og:image:height', content: '584'},
        { vmid: 'og:url', property: 'og:url', content: 'https://lavaforgood.com'},
        { vmid: 'og:type', property: 'og:type', content: 'website'},
        { vmid: 'og:site_name', property: 'og:site_name', content: 'Lava for Good'},
        { vmid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image'},
        { vmid: 'twitter:title', name: 'twitter:title', content: 'Lava for Good'},
        { vmid: 'twitter:description', name: 'twitter:description', content: 'Unparalleled access and insights into the heart of social justice in America'},
        { vmid: 'twitter:image:src', name: 'twitter:image:src', content: 'https://lavaforgood.com/images/sticker_logo.png'},
        { vmid: 'twitter:image:alt', name: 'twitter:image:alt', content: 'Lava for Good'},
        // Google / Schema.org markup:
        { vmid: 'nameprop', itemprop: 'name', content: 'Lava for Good'},
        { vmid: 'descprop', itemprop: 'description', content: 'Unparalleled access and insights into the heart of social justice in America'},
        { vmid: 'imageprop', itemprop: 'image', content: 'https://lavaforgood.com/images/sticker_logo.png'},
      ],
    },
  }
</script>
