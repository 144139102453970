<template>
<v-card
    v-if="(seriesSlug === 'bone-valley' || seriesSlug === 'war-on-drugs' || seriesSlug === 'earwitness' || seriesSlug === 'erased') && cards[seriesSlug][episodeIdentifier]"
    class="mx-auto"
    max-width="900"
    flat
  >

  <v-card-text
    v-if="seriesSlug === 'bone-valley'"
  >
  <v-icon small>mdi-file-outline</v-icon><a href="https://s3.amazonaws.com/media.metarhythm.com/podcaster/1989_trial_transcript_full.pdf">Complete 1989 Trial Transcript (Large file: 2GB)</a>
  </v-card-text>
    <v-container fluid>
      <v-row>
        <v-col
          v-if="seriesSlug === 'erased' && episodeIdentifier === 'S1E0'"
          cols="12"
        >
          <iframe :width="getVideoWidth(560)" :height="getVideoHeight(315)" src="https://www.youtube.com/embed/Yuc6nKOAD9k" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>,
        </v-col>
        <v-col
          v-if="seriesSlug === 'earwitness' && episodeIdentifier === 'S1E0'"
          cols="12"
        >
          <iframe :width="getVideoWidth(560)" :height="getVideoHeight(315)" src="https://www.youtube.com/embed/4mJbV5L_Lhg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>,
        </v-col>
         <v-col
          v-if="seriesSlug === 'war-on-drugs' && episodeIdentifier === 'S1E11'"
          cols="12"
        >
          <iframe :width="getVideoWidth(560)" :height="getVideoHeight(315)" src="https://www.youtube.com/embed/nT2EFwOhAn8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>,
        </v-col>
        <v-col
          v-for="card in cards[seriesSlug][episodeIdentifier]"
          :key="card.title"
          cols="12"
          :md="card.flex"

        >
          <v-card >
            <v-img
              :src="card.src"
              :height="getHeight(card.height)"
              cover
            >
            </v-img>
             <v-card-subtitle v-text="card.title"></v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="seriesSlug === 'bone-valley' && episodeIdentifier === 'S1E9'"
          cols="12"
        >
          <v-card>
            <video controls :width="getVideoWidth(560)">
              <source src="https://s3.amazonaws.com/media.metarhythm.com/podcaster/leo_2011_hardee.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            <v-card-subtitle v-text="videoTextE9"></v-card-subtitle>
          </v-card>
        </v-col>
        <v-col
          v-if="seriesSlug === 'bone-valley' && episodeIdentifier === 'S1E7'"
          cols="12"
        >
          <v-card>
            <audio controls>
              <source src="https://s3.amazonaws.com/media.metarhythm.com/podcaster/jeremy_scott.mp3" type="audio/mpeg">
              Your browser does not support the audio tag.
            </audio>
            <v-card-subtitle v-text="audioTextE7"></v-card-subtitle>
          </v-card>
        </v-col>
        <v-col
          v-if="seriesSlug === 'bone-valley' && episodeIdentifier === 'S1E5'"
          cols="12"
        >
          <v-card>
            <audio controls>
              <source src="https://s3.amazonaws.com/media.metarhythm.com/podcaster/jeremy_phonecall_1_11_05.mp3" type="audio/mpeg">
              Your browser does not support the audio tag.
            </audio>
            <v-card-subtitle v-text="audioTextE5"></v-card-subtitle>
          </v-card>
        </v-col>
       <v-col
          v-if="seriesSlug === 'bone-valley' && episodeIdentifier === 'S1E2'"
          cols="12"
          :md="4"
        >
          <v-card>
            <audio controls>
              <source src="https://s3.amazonaws.com/media.metarhythm.com/podcaster/Shove_it_Over_full.mp3" type="audio/mpeg">
              Your browser does not support the audio tag.
            </audio>
            <v-card-subtitle v-text="audioText"></v-card-subtitle>
          </v-card>
        </v-col>
        <v-col
          v-if="seriesSlug === 'bone-valley' && episodeIdentifier === 'S1E2'"
          cols="12"
          :md="8"
        >
          <iframe :width="getVideoWidth(560)" :height="getVideoHeight(315)" src="https://www.youtube.com/embed/lA6bruIZ0Ak" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>,
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>

export default {
  name: 'ImageGrid',
  props: { episodeIdentifier: String, seriesSlug: String },
  data: () => ({
      audioText: '"Shove It Over," a lining rhythm learned on a railroad construction camp near Lakeland. Performed by Zora Neale Hurston on June 18, 1939.',
      audioTextE5: 'Recorded phone call between Jeremy Scott and his grandmother, Earline Sandage, following Jeremy\'s interrogation by detectives from the Polk County Sheriff\'s Office about his newly-identified fingerprints lifted from Michelle Schofield\'s vehicle.',
      audioTextE7: 'Jeremy Scott confesses, on tape, to the murder of Michelle Schofield. Statement recorded by Private Investigator Pat McKenna in October 2017.',
      videoTextE9: 'Leo Schofield gives a sermon at Hardee Correctional Institution.',
      cards: {
        'erased' : {
          'S1E0': [ ],
        },
        'earwitness' : {
          'S1E0': [ ],
          'S1E1': [
            { title: 'Toforest Johnson in an undated photo prior to his arrest', src: '/earwitness/E1a.png', flex: 6, height: '500px' },
            { title: 'Retired Alabama Attorney General Bill Baxley at his law office, 2021', src: '/earwitness/E1b.png', flex: 6, height: '500px' },
            { title: 'More brand menthol cigarettes and lighter found in Deputy William Hardy\'s pocket after his murder', src: '/earwitness/E1c.png', flex: 6, height: '500px' },
            { title: 'Incident report written by Officer Ricky Sharit indicating the large law enforcement response to the hotel early in the morning of July 19, 1995', src: '/earwitness/E1d.png', flex: 6, height: '500px' },
            { title: 'Deputy Wiliam Hardy\'s obituary in a Birmingham newspaper', src: '/earwitness/E1e.png', flex: 12, height: '500px' },
            { title: 'Crime scene photos taken by law enforcement at the back door of the Crown Sterling suites, where Deputy Hardy was shot, and inside the hotel\'s atrium where Hardy had been sitting at a table, smoking and drinking coffee moments earlier', src: '/earwitness/E1f.png', flex: 6, height: '500px' },
            { title: 'Press release circulated by the Jefferson County Sheriff\'s Office the morning after the murder, listing suspicious vehicles and announcing a reward for information', src: '/earwitness/E1g.jpg', flex: 6, height: '500px' },
            { title: 'Letter from Sheriff Mel Bailey to DA David Barber, requesting help soliciting an additional $10,000 in reward money from the Governor\'s office, written two days after the murder', src: '/earwitness/E1h.png', flex: 6, height: '500px' },
            { title: 'The atrium of the hotel as it looks today, mostly unchanged since 1995 when it was the Crown Sterling Suites', src: '/earwitness/E1i.png', flex: 6, height: '500px' },
            { title: 'Newspaper article announcing Governor Fob James\' $10,000 reward for information about the murder, bringing total reward offer to $20,000', src: '/earwitness/E1j.png', flex: 12, height: '375px' },
            { title: 'The main entrance of the hotel as it looks today, also mostly unchanged since 1995 when it was the Crown Sterling Suites', src: '/earwitness/E1k.png', flex: 6, height: '500px' },
            { title: 'The back door of the hotel where Deputy William Hardy was shot, as it looks today', src: '/earwitness/E1l.png', flex: 6, height: '500px' },
          ],
          'S1E2': [
            { title: 'Retired detective Tony Richardson, 2022', src: '/earwitness/E2a.png', flex: 6, height: '500px' },
            { title: 'Tony Richardson reviews documents during his interview with Beth Shelburne, 2022', src: '/earwitness/E2b.jpg', flex: 6, height: '500px' },
            { title: 'Toforest Johnson\'s cousin, Antonio Green, 2021', src: '/earwitness/E2c.png', flex: 12, height: '500px' },
            { title: 'Police report Detective Tony Richardson wrote after first receiving the tip from Rosa Hardy, Yolanda Chamber\'s mother. The report indicates that Yolanda identified Quintez Wilson and Omar Berry as being involved in the killing.', src: '/earwitness/E2d.png', flex: 6, height: '500px' },
            { title: 'Police report Detective Tony Richardson wrote after first interrogating Ardragus Ford', src: '/earwitness/E2e.png', flex: 6, height: '500px' },
          ],
          'S1E3': [
            { title: 'Yolanda Chambers with her son, Malik', src: '/earwitness/E3a.jpg', flex: 12, height: '500px' },
            { title: 'Yolanda Chambers with her son, Malik', src: '/earwitness/E3b.jpg', flex: 6, height: '500px' },
            { title: 'Yolanda in an undated school picture, around age 10', src: '/earwitness/E3c.jpg', flex: 6, height: '500px' },
            { title: 'The remote site in Bessemer, AL where Yolanda Chambers\' body was discovered on Valentine\'s Day, 2009', src: '/earwitness/E3d.jpg', flex: 6, height: '500px' },
            { title: 'Page from Yolanda Chamber\'s funeral program', src: '/earwitness/E3e.jpg', flex: 6, height: '500px' },
            { title: 'Rosa Hardy at her apartment, 2021', src: '/earwitness/E3f.jpg', flex: 6, height: '500px' },
            { title: 'Callie Greer, at one of her offices in Birmingham, 2021', src: '/earwitness/E3g.jpg', flex: 6, height: '500px' },
            { title: 'An excerpt from the pre-trial hearing where Yolanda Chambers testified on the stand that under pressure from police, she told them whatever they wanted to hear about the murder', src: '/earwitness/E3h.jpg', flex: 6, height: '500px' },
            { title: 'Another excerpt from Yolanda\'s testimony at the pre-trial hearing ', src: '/earwitness/E3i.jpg', flex: 6, height: '500px' },
          ],
          'S1E4' : [
            { title: 'Kenyara Pickett at her home in Birmingham, 2021', src: '/earwitness/E4a.jpg', flex: 6, height: '500px' },
            { title: 'Velonique Sanders at her home in Georgia, 2021', src: '/earwitness/E4b.jpg', flex: 6, height: '500px' },
            { title: 'Stanley Chandler at his home in Birmingham, 2021', src: '/earwitness/E4c.jpg', flex: 12, height: '500px' },
            { title: 'Barbetta "Mama Cat" Hunt at her home in Birmingham, 2021', src: '/earwitness/E4d.jpg', flex: 6, height: '500px' },
            { title: 'Richard Jaffe being interviewed by Beth Shelburne at his offices, 2021', src: '/earwitness/E4e.jpg', flex: 6, height: '500px' },
            { title: 'Polaroids of exterior and interior of Tee\'s taken by detectives during the investigation', src: '/earwitness/E4f.jpg', flex: 6, height: '500px' },
            { title: 'Incident report written by detective Tony Richardson logging the day that Toforest Johnson, Ardragus Ford, Quintez Wilson, and Omar Berry were arrested for Deputy Hardy\'s murder, with "Case Closed" noted in his neat cursive', src: '/earwitness/E4g.jpg', flex: 6, height: '500px' },
            { title: 'Report noting a caller to the special tipline, noting that the houseman at the Crown Sterling Suites, Jerry McDaniel, saw Deputy Hardy stopping "6 black males" trying to enter the back door of the hotel about two hours before he was murdered there', src: '/earwitness/E4h.jpg', flex: 6, height: '500px' },
            { title: 'Newspaper article from July 27, 1995, describing Latanya Henderson and Yolanda Chamber\'s intial involvement with police as witnesses. Media does not typically report the names of minors who are questioned by police. It\'s unclear why police released Yolanda and LaTanya\'s names to the media. ', src: '/earwitness/E4i.jpg', flex: 12, height: '375px' },
            { title: 'Newspaper article from August 23, 1995, describing Latanya Henderson\'s arrest for hindering prosecution', src: '/earwitness/E4j.jpg', flex: 6, height: '500px' },
            { title: 'Marshall Kelly Cummings at his home Morris, AL, 2021', src: '/earwitness/E4k.jpg', flex: 6, height: '500px' },
            { title: 'Newspaper article from September 1990, profiling then-Jefferson County District Attorney David Barber', src: '/earwitness/E4l.jpg', flex: 12, height: '375px' },
            { title: 'Ardragus Ford\'s black Monte Carlo with broken driver\'s side door, seized and searched by detectives', src: '/earwitness/E4m.jpg', flex: 12, height: '375px' },
          ],
          'S1E5' : [
            { title: 'Toforest Johnson\'s mother, Donna Johnson, 2019', src: '/earwitness/E5a.jpg', flex: 6, height: '500px' },
            { title: 'Toforest Johnson\'s oldest daughter, Shanaye Poole, 2019', src: '/earwitness/E5b.jpg', flex: 6, height: '500px' },
            { title: 'Toforest Johnson\'s immediate family including uncles Elmer Johnson and Donald Johnson, cousin Antonio Green, mother Donna Johnson, brother Ronald, and Ronald\'s young sons', src: '/earwitness/E5c.jpg', flex: 12, height: '500px' },
            { title: 'Jeff Wallace, former prosecutor, who brought cases against Toforest Johnson and Ardragus Ford, photographed at the church he attends in Birmingham, 2021', src: '/earwitness/E5d.jpg', flex: 6, height: '500px' },
            { title: 'Ardragus Ford\'s cousin, Nicole Blount Kirskey, 2021', src: '/earwitness/E5e.jpg', flex: 6, height: '500px' },
            { title: 'The hat Deputy Bill Hardy wore religiously, with a bullet hole through the brim', src: '/earwitness/E5f.jpg', flex: 12, height: '500px' },
            { title: 'Newspaper article on the jury verdict recommending the death penalty for Toforest Johnson', src: '/earwitness/E5g.jpg', flex: 6, height: '500px' },
            { title: 'Newspaper article describing the outcome of Ardragus Ford\'s first trial', src: '/earwitness/E5h.jpg', flex: 6, height: '500px' },
            { title: 'Newspaper article about the beginning of Toforest Johnson\'s second trial', src: '/earwitness/E5i.jpg', flex: 6, height: '500px' },
            { title: 'Newspaper article reporting the results of Toforest\'s first trial', src: '/earwitness/E5j.jpg', flex: 6, height: '500px' },
          ],
          'S1E6' : [
            { title: 'Monique Hicks, one of the jurors who voted for guilt at Toforest Johnson\'s second trial', src: '/earwitness/E6a.jpg', flex: 6, height: '500px' },
            { title: 'Jay Crane, another juror who voted to convict Toforest Johnson', src: '/earwitness/E6b.jpg', flex: 6, height: '500px' },
            { title: 'The $5000 check issued to Violet Ellison', src: '/earwitness/E6c.jpg', flex: 12, height: '375px' },
            { title: 'Acknowledgement of receipt', src: '/earwitness/E6d.jpg', flex: 12, height: '375px' },
            { title: 'Judge Alfred Bahakel\'s court order for payment of $5000 to Violet Ellison, noting that he has been informed Ellison came forward "pursuant to the reward"', src: '/earwitness/E6e.jpg', flex: 6, height: '500px' },
            { title: 'Violet Ellison\'s application for the reward', src: '/earwitness/E6f.jpg', flex: 6, height: '500px' },
            { title: 'A packed courtroom at Toforest Johnson\'s 2019 hearing in Judge Pulliam\'s courtroom to determine whether Violet Ellison\'s secret reward payment amounted to prosecutorial misconduct.', src: '/earwitness/E6g.jpg', flex: 12, height: '500px' },
            { title: 'Toforest consults with attorneys Kathryn Miller and Katherine Moss in Judge Pulliam\'s courtroom in 2019; attorneys Patrick Mulvaney (l) and Ty Alper (r) are in the background', src: '/earwitness/E6h.jpg', flex: 12, height: '500px' },
            { title: 'Toforest and attorney Kathryn Miller at his 2019 hearing', src: '/earwitness/E6i.jpg', flex: 12, height: '500px' },
            { title: 'Violet Ellison takes the stand at Toforest Johnson\'s 2019 court hearing', src: '/earwitness/E6j.jpg', flex: 12, height: '500px' },
            { title: 'The Alabama Judicial Building in Montgomery, housing both the state Supreme Court and Court of Criminal Appeals. Both courts have denied relief to Toforest Johnson.', src: '/earwitness/E6k.jpg', flex: 12, height: '500px' },
            { title: 'The judges of the Alabama Court of Criminal Appeals', src: '/earwitness/E6l.jpg', flex: 12, height: '500px' },
         ],
         'S1E7': [
           { title: 'Murika Wilson at her home in Birmingham, 2022', src: '/earwitness/E7a.jpg', flex: 6, height: '500px' },
           { title: 'Violet Ellison is listed on court documents as a witness for the State at Murika Wilson\'s murder trial', src: '/earwitness/E7b.jpg', flex: 6, height: '500px' },
           { title: 'The judge in Murika Wilson\'s murder trial makes a note in the case file, indicating that she has been acquitted and released', src: '/earwitness/E7c.jpg', flex: 6, height: '500px' },
         ],
         'S1E8': [
           { title: 'Toforest Johnson in an undated photo taken during a prison visit with (L-R) his mother, Donna, his daughter Shanaye Poole, and son Tremaine Perry', src: '/earwitness/E8a.jpg', flex: 6, height: '500px' },
           { title: 'Toforest Johnson in an undated photo taken during a prison visit with (L-R) his daughter Shanaye Poole, daughter Akeriya Lawler, and son Tremaine Perry', src: '/earwitness/E8b.jpg', flex: 6, height: '500px' },
           { title: 'Toforest Johnson in an undated photo taken during a prison visit with his mother, Donna Johnson', src: '/earwitness/E8c.jpg', flex: 6, height: '500px' },
           { title: 'Toforest Johnson in a photo taken during a prison visit with his mother, Donna and son, Tremaine Perry', src: '/earwitness/E8d.jpg', flex: 6, height: '500px' },
           { title: 'Toforest Johnson in an undated photo taken during a prison visit with his mother, Donna', src: '/earwitness/E8e.jpg', flex: 6, height: '500px' },
           { title: 'Four of Toforest\'s children (L-R) Akeriya Lawler, Maurice Myers, Shanaye Poole, Tremaine Perry, photographed in Birmingham, 2021', src: '/earwitness/E8f.jpg', flex: 6, height: '500px' },
           { title: 'Toforest Johnson in an undated photo taken during a prison visit with his sons (L-R), Maurice Myers and Tremaine Perry', src: '/earwitness/E8g.jpg', flex: 12, height: '500px' },
           { title: 'Toforest\'s son Robby Foster, with his family, 2021', src: '/earwitness/E8h.jpg', flex: 6, height: '500px' },
           { title: 'Toforest\'s daughter, Akeriya "Muffin" Lawler, reads one of the letters her dad sent her from prison to Beth, 2021', src: '/earwitness/E8i.jpg', flex: 6, height: '500px' },
           { title: 'One of many colorful envelopes Toforest sent his daughter, Akeriya "Muffin" Lawler, from prison', src: '/earwitness/E8j.jpg', flex: 12, height: '500px' },
           { title: 'A holiday card Toforest sent his daughter from prison when she was a child', src: '/earwitness/E8k.jpg', flex: 12, height: '500px' },
           { title: 'The banner sponsored by Greater Birmingham Ministries, hung on the front of their building, which faced the stadium hosting the 2021 World Games in Birmingham ', src: '/earwitness/E8l.jpg', flex: 6, height: '500px' },
           { title: 'The banner hung in front of the Highlands United Methodist Church at the kickoff for its tour of churches across the state of Alabama', src: '/earwitness/E8m.jpg', flex: 6, height: '500px' },
           { title: 'Newspaper clipping from 2021 about the widespread support for DA Danny Carr\'s call for a new trial for Toforest Johnson', src: '/earwitness/E8n.jpg', flex: 6, height: '500px' },
         ]
        },
        'war-on-drugs' : {
          'S1E11': [
            { title: '', src: '/war-on-drugs/E11a.jpg', flex: 12, height: '500px' },
            { title: '', src: '/war-on-drugs/E11b.jpg', flex: 12, height: '500px' },
            { title: '', src: '/war-on-drugs/E11c.jpg', flex: 12, height: '500px' },
            { title: '', src: '/war-on-drugs/E11d.jpg', flex: 12, height: '500px' },
            { title: '', src: '/war-on-drugs/E11e.jpg', flex: 6, height: '500px' },
            { title: '', src: '/war-on-drugs/E11f.jpg', flex: 6, height: '500px' },
            { title: '', src: '/war-on-drugs/E11g.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11h.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11i.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11j.jpg', flex: 12, height: '500px' },
            { title: '', src: '/war-on-drugs/E11k.jpg', flex: 6, height: '500px' },
            { title: '', src: '/war-on-drugs/E11l.jpg', flex: 6, height: '500px' },
            { title: '', src: '/war-on-drugs/E11m.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11n.jpg', flex: 6, height: '500px' },
            { title: '', src: '/war-on-drugs/E11o.jpg', flex: 6, height: '500px' },
            { title: '', src: '/war-on-drugs/E11p.jpg', flex: 12, height: '500px' },
            { title: '', src: '/war-on-drugs/E11q.jpg', flex: 6, height: '500px' },
            { title: '', src: '/war-on-drugs/E11r.jpg', flex: 6, height: '500px' },
            { title: '', src: '/war-on-drugs/E11s.jpg', flex: 12, height: '500px' },
            { title: '', src: '/war-on-drugs/E11t.jpg', flex: 12, height: '500px' },
            { title: '', src: '/war-on-drugs/E11u.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11v.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11w.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11x.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11y.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11z.jpg', flex: 12, height: '375px' },
            { title: '', src: '/war-on-drugs/E11zz.jpg', flex: 12, height: '500px' },
          ]
        },
        'bone-valley' : {
          'S1E1': [
            {
              title: 'The back of Judge Scott Cupp\'s business card, handed to Gilbert King at a conference in Naples, Florida, 2018.',
              src: '/bone-valley/E1a.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'The Honorable Judge Scott Cupp, Twentieth Judicial Circuit Court in Charlotte County, Florida. ',
              src: '/bone-valley/E1c.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Judge Scott Cupp is interviewed in Naples, Florida, by a crew for ABC News 20/20.',
              src: '/bone-valley/E1d.jpg',
              flex: 6,
              height: '500px'
            },
            {title: 'Michelle Saum, 1986(?)', src: '/bone-valley/E1e.jpg', flex: 6, height: '500px'},
            {title: 'Michelle Saum poses with a bicycle.', src: '/bone-valley/E1f.jpg', flex: 6, height: '500px'},
            {
              title: 'Leo Schofield\'s portrait from the Lake Gibson High School yearbook. ',
              src: '/bone-valley/E1g.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Michelle Schofield at her wedding reception, following her marriage to Leo. Southside Assembly of God, Lakeland, FL. August 29, 1986. ',
              src: '/bone-valley/E1h.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Leo and Michelle Schofield light a candle during their wedding ceremony at the Southside Assembly of God in Lakeland. August 29, 1986.',
              src: '/bone-valley/E1i.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'Leo and Michelle Schofield pose for a photo at their wedding reception. Southside Assembly of God, Lakeland, FL. August 29, 1986.',
              src: '/bone-valley/E1j.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Michelle Schofield (center) stands with her best friend, Michell McCluskey, at Southside Assembly of God, Lakeland, FL. August 29, 1986.',
              src: '/bone-valley/E1k.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Leo and Michelle Schofield stand with a group of family and friends in front of their orange Mazda stationwagon.',
              src: '/bone-valley/E1l.jpg',
              flex: 6,
              height: '375px'
            },
            {
              title: 'Tom\'s Restaurant, where Michelle Schofield worked her waitressing shift the night she disappeared. Photographed 2018.',
              src: '/bone-valley/E1m.jpg',
              flex: 6,
              height: '375px'
            },
            {
              title: 'Missing person flyer featuring Michelle Schofield\'s photo and identifying information.',
              src: '/bone-valley/E1o.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Leo and Michelle Schofield\'s orange Mazda stationwagon, located along Interstate 4, February 26, 1987.',
              src: '/bone-valley/E1p.jpg',
              flex: 6,
              height: '500px'
            },
            //height is 500 or 375 flex is 12 or 6
          ],
          'S1E2': [
            {
              title: 'Two men kneel with the fossilized teeth of a mastodon, unearthed during phosphate mining, 1946. ',
              src: '/bone-valley/E2c.jpg',
              flex: 6,
              height: '375px'
            },
            {
              title: 'The Bigger Digger, world\'s largest dragline, mining phosphate in Florida\'s "Bone Valley," along Peace River. February 1948.',
              src: '/bone-valley/E2g.jpg',
              flex: 6,
              height: '375px'
            },
            {
              title: 'Leo Schofield talks to investigators alongside Interstate-4, where Michelle\'s Mazda stationwagon had been located. February 26, 1987.',
              src: '/bone-valley/E2l.jpg',
              flex: 6,
              height: '375px'
            },
            {
              title: 'The orange Mazda is photographed at the Florida Department of Law Enforcement\'s Orlando Crime Lab, where it was towed for inspection.',
              src: '/bone-valley/E2p.jpg',
              flex: 6,
              height: '375px'
            },
            {
              title: 'Aerial photo taken by helicopter, showing the canal where Michelle Schofield\'s body was found. February 27, 1987. ',
              src: '/bone-valley/E2r.jpg',
              flex: 12,
              height: '500px'
            },
            {title: '', src: '/bone-valley/E2s.jpg', flex: 12, height: '500px'},
            {
              title: 'Crime scene tape is tied to a sign along SR-33 that reads "NO DUMPING OF RUBBISH."',
              src: '/bone-valley/E2ii.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'The Polk County Sheriff\'s Office helicopter sits alongside State Road 33, across from the location where Michelle\'s body was discovered. ',
              src: '/bone-valley/E2aa.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'Michelle Schofield\'s body is found floating face down, partially covered with a plywood board.',
              src: '/bone-valley/E2w.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Michelle\'s body and the plywood covering it can be seen at the edge of the canal, in the center of photo.',
              src: '/bone-valley/E2v.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Detectives from the Polk County Sheriff\'s Office stand at the scene where Michelle\'s body was found.',
              src: '/bone-valley/E2y.jpg',
              flex: 6,
              height: '500px'
            },
            {title: '', src: '/bone-valley/E2z.jpg', flex: 6, height: '500px'},
            {
              title: 'Leo Schofield is photographed pacing along the side of State Road 33, after the discovery of his wife\'s body. When prompted with this photo, Leo explains: "That man right there is totally lost."',
              src: '/bone-valley/E2gg.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Leo Sr\'s blue pickup truck parked alongside State Road 33.',
              src: '/bone-valley/E2hh.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'Michelle Schofield\'s jacket is laid out and photographed at the scene where her body was discovered.',
              src: '/bone-valley/E2dd.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'The plywood that was found covering Michelle\'s body, now kept in the evidence room beneath the Polk County courthouse.',
              src: '/bone-valley/E2oo.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'The bloodstained top Michelle Schofield was wearing the night she was killed.',
              src: '/bone-valley/E2rr.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'The Downy Fabric Softener bottle found in the back of the orange Mazda stationwagon with a smear of Michelle Schofield\'s blood.',
              src: '/bone-valley/E2ss.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Photograph of Michelle Schofield\'s top, taken the day her body was discovered. ',
              src: '/bone-valley/E2uu.jpg',
              flex: 6,
              height: '500px'
            },
            {title: 'Sheriff Grady Judd of Polk County.', src: '/bone-valley/E2vv.jpg', flex: 6, height: '500px'},
            {
              title: 'June 1988, nearly 16 months after Michelle Schofield is killed, her husband Leo is charged with the crime. ',
              src: '/bone-valley/E2yy.jpg',
              flex: 12,
              height: '500px'
            },
          ],
          'S1E3': [
            {title: '', src: '/bone-valley/E3a.jpg', flex: 12, height: '500px'},
            {title: '', src: '/bone-valley/E3b.jpg', flex: 6, height: '500px'},
            {title: '', src: '/bone-valley/E3c.jpg', flex: 6, height: '500px'},
            {title: 'Portrait of Leo Schofield, Sr.', src: '/bone-valley/E3d.jpg', flex: 6, height: '500px'},
            {
              title: 'A letter from Leo Schofield to his family, written while he awaited trial in the Polk County jail.',
              src: '/bone-valley/E3e.jpg',
              flex: 6,
              height: '500px'
            },
            {title: '', src: '/bone-valley/E3f.jpg', flex: 12, height: '500px'},
            {title: '', src: '/bone-valley/E3g.jpg', flex: 12, height: '375px'},
            {
              title: 'Leo and Michelle Schofield\'s neighborhood is photographed from the air.',
              src: '/bone-valley/E3h.jpg',
              flex: 12,
              height: '500px'
            },
            {title: '', src: '/bone-valley/E3i.jpg', flex: 12, height: '500px'},
            {title: '', src: '/bone-valley/E3j.jpg', flex: 6, height: '500px'},
            {title: '', src: '/bone-valley/E3k.jpg', flex: 6, height: '500px'},
            {title: '', src: '/bone-valley/E3l.jpg', flex: 12, height: '500px'},
            {
              title: 'Michelle Schofield, buried with her maiden name Saum, Oak Hill Burial Park, Lakeland, Florida. Michelle\'s surname was changed following the conviction of her husband for her murder. ',
              src: '/bone-valley/E3m.jpg',
              flex: 12,
              height: '500px'
            },
          ],
          'S1E4': [
            {
              title: 'Leo Schofield stands in the prison yard and shows off his arm, tattooed with an electric guitar.',
              src: '/bone-valley/E4a.jpg',
              flex: 6,
              height: '500px'
            },
            {title: 'Leo and Crissie Carter Schofield.', src: '/bone-valley/E4b.jpg', flex: 6, height: '500px'},
            {
              title: 'The orange Mazda stationwagon, as it was found in February 1987 -- broken down and abandoned along Interstate-4.',
              src: '/bone-valley/E4c.jpg',
              flex: 12,
              height: '375px'
            },
            {title: 'Fingerprints on the hood of the Mazda.', src: '/bone-valley/E4d.jpg', flex: 12, height: '375px'},
            {
              title: 'Fingerprints lifted from the interior driver\'s side window of the orange Mazda stationwagon. These prints remained unidentified until they were finally run through the statewide AFIS system in December 2004.',
              src: '/bone-valley/E4e.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Rolled fingerprints taken from Jeremy Scott after he was charged with arson in 1985. These prints were manually compared to those lifted from the Mazda in 1987, to validate the AFIS findings. The prints are a match.',
              src: '/bone-valley/E4f.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Mugshot of Jeremy Scott, the man who left fingerprints in Michelle Schofield\'s Mazda stationwagon.',
              src: '/bone-valley/E4g.jpg',
              flex: 6,
              height: '500px'
            },
          ],
          'S1E5': [
            {
              title: 'The Polk Tribune, covering the 1985 trial of Jeremy Scott for the murder of Jewel Johnson. ',
              src: '/bone-valley/E5a.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Following Jeremy Scott\'s acquittal in the murder of Jewel Johnson, the State Attorney\'s Office pursued charges of arson. Jeremy\'s public defender filed a motion alleging prosecutorial misconduct, which described Jeremy as "extremely emotionally disturbed." Jeremy was eventually convicted on the charge, and sent to an adult state prison. ',
              src: '/bone-valley/E5b.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'The Tampa Tribune, published September 8, 1989. ',
              src: '/bone-valley/E5c.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Jeremy Scott is resentenced, and removed from death row. The Tampa Tribune, published November 6, 1992. ',
              src: '/bone-valley/E5d.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Mugshot of Larry Brian Hall, Jeremy Scott\'s co-defendent in the 1988 murder of Donald Morehead. ',
              src: '/bone-valley/E5e.jpg',
              flex: 6,
              height: '500px'
            },
            {title: 'Jeremy Scott.', src: '/bone-valley/E5f.jpg', flex: 6, height: '500px'},
            {title: 'Jeremy Scott.', src: '/bone-valley/E5g.jpg', flex: 6, height: '500px'},
            {title: 'Jeremy Scott.', src: '/bone-valley/E5h.jpg', flex: 6, height: '500px'},
          ],
          'S1E6': [
            {
              title: 'The Tampa Tribune, published April 13, 1987.',
              src: '/bone-valley/E6a.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'Contact sheet showing images of Joseph Lavair\'s taxi cab, including the Confederate flag baseball cap that was left in the back seat when the suspect fled the scene of the crash.  ',
              src: '/bone-valley/E6b.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Contact sheet showing photos of the cab\'s interior.',
              src: '/bone-valley/E6c.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'The baseball cap left in the cab by the suspect, emblazoned with a skull and Confederate flag.',
              src: '/bone-valley/E6d.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'A crime scene technician\'s sketch of the baseball cap, a key piece of evidence.',
              src: '/bone-valley/E6e.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Composite sketch created from the statements of multiple witnesses who saw the suspect fleeing the scene of the crashed taxi cab.',
              src: '/bone-valley/E6f.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Another suspect sketch, created from witness statements.',
              src: '/bone-valley/E6g.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'A diagram showing the route the taxi cab travelled before crashing into a utility pole and knocking out power in Intercession City.',
              src: '/bone-valley/E6h.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'The Orlando Sentinel, published April 18, 1987. Caption printed beneath the picture: "Buddy Shepherd (left), Larry Taggett and Jim Wells guard Daniel Otte after he was arrested by Osceola County deputies Friday and charged with the murder of a Kissimmee taxicab driver." ',
              src: '/bone-valley/E6i.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Mugshot of Daniel Otte following his arrest in the murder of Joseph Lavair.',
              src: '/bone-valley/E6j.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'The Orlando Sentinel covers the trial of Daniel Otte.',
              src: '/bone-valley/E6k.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'The Orlando Sentinel, published October 21, 1987.',
              src: '/bone-valley/E6l.jpg',
              flex: 12,
              height: '500px'
            },
          ],
          'S1E7': [
            {
              title: 'The War Room at Hardee Correctional Institution, where inmates pray and conduct "spiritual warfare."',
              src: '/bone-valley/E7a.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'The interior of Hardee C.I.\'s War Room, where Leo knelt and prayed for Jeremy Scott.',
              src: '/bone-valley/E7b.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'A letter to Jeremy Scott, written by Leo Schofield\'s attorney, Andrew Crawford. ',
              src: '/bone-valley/E7c.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Jeremy Scott writes back to Andrew Crawford: "who really know what happen that night. the state don\'t, you don\'t. help me, I help you... I die with this information" ',
              src: '/bone-valley/E7d.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Excerpt from notes taken by Andrew Crawford\'s colleague, Sean Costis, who witnessed the call between Crawford and Jeremy Scott, during which Jeremy confessed to the murder of Michelle Schofield. ',
              src: '/bone-valley/E7e.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Another letter from Jeremy Scott, written to Andrew Crawford: "I Jeremy Lynn Scott killed Mrs. Schofield... it time to end all this."',
              src: '/bone-valley/E7f.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Leo and his wife Crissie smile and pose with their daughter, Ashley. ',
              src: '/bone-valley/E7g.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Leo\'s daughter, Ashley, shows off her new tattoo, matching her father\'s.   ',
              src: '/bone-valley/E7h.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Transcription of Jeremy Scott\'s recorded confession to the murder of Michelle Schofield.',
              src: '/bone-valley/E7i.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'The Tampa Tribune, published August 23, 1997. Assitant State Attorney John Aguero is removed from his position as the director of homicide by his boss, State Attorney Jerry Hill. Hill said, "It is my plan to give him a breather, then we\'ll put him back in the game. But it would not bother me to see John take a year off. He\'s a terrific resource, and we\'re doing our part to take care of that resource."',
              src: '/bone-valley/E7j.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Assitant State Attorney John Aguero sits in the Polk County jail after being charged with domestic battery following an argument with his wife in May 2011. Reports published in the Lakeland Ledger state that the couple was divorcing, and had been drinking throughout the day. ',
              src: '/bone-valley/E7k.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Leo Schofield is directed by a bailiff in the Polk County Courthouse on the first day of his evidentiary hearing, October 12, 2017.  ',
              src: '/bone-valley/E7l.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'Jeremy Scott, handcuffed, is sworn in prior to his testimony at the evidentiary hearing in October 2017. ',
              src: '/bone-valley/E7m.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Jeremy Scott stands at a podium to testify during the October 2017 evidentiary hearing. Throughout his testimony, Jeremy maintained that he alone killed Michelle Schofield. ',
              src: '/bone-valley/E7n.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Seth Miller (left), of the Innocence Project of Florida, and Andrew Crawford sit and listen to the State\'s presentation during the October 2017 evidentiary hearing. Miller and Crawford both serve as co-counsel for Leo Schofield.',
              src: '/bone-valley/E7o.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Leo sits with his face in his hands during the hearing. ',
              src: '/bone-valley/E7p.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Assistant State Attorney Victoria Avalon shows Jeremy Scott photos from Michelle Schofield\'s autopsy during the 2017 evidentiary hearing. ',
              src: '/bone-valley/E7q.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'The Tampa Bay Times, published October 13, 2017. Journalist Anastasia Dawson characterizes Jeremy Scott\'s statement -- "I didn\'t do that" -- as a recantation, despite Jeremy\'s following testimony in which he maintained his confession to Michelle Schofield\'s murder. ',
              src: '/bone-valley/E7r.jpg',
              flex: 12,
              height: '500px'
            },
          ],
          'S1E8': [
            {
              title: 'Gilbert King stands and talks to Leo Schofield\'s wife, Crissie, and daughter, Ashley, outside the room where a hearing before the 2nd District Court of Appeals will be held. The hearing was held on September 25, 2019, to determine whether the lower court made the correct ruling, following a 2017 evidentiary hearing.  ',
              src: '/bone-valley/E8a.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Crissie Carter Schofield and Ashley Schofield talk about their expectations for the 2nd DCA hearing, and their recent visits to Hardee Correctional Institution, to visit Leo.',
              src: '/bone-valley/E8b.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Leo\'s attorney, Seth Miller of the Innoncence Project of Florida, shares his impressions of the panel of three judges, following his oral arguments. ',
              src: '/bone-valley/E8c.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Politcal advertisement published in the Tampa Tribune, promoting Jerry Hill\'s campaign for State Attorney of the 10th Circuit, which incluses Polk, Highlands, and Hardee counties. Hill won the election, and would hold the post of State Attorney for over three decades. ',
              src: '/bone-valley/E8d.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'The Lakeland Ledger announces the retirement of State Attorney Jerry Hill. The position of 10th Circuit State Attorney is now held by Brian Haas. ',
              src: '/bone-valley/E8e.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'A letter written by Michelle Schofield\'s brother, Jessie Saum, in support of Leo Schofield\'s parole. This letter was never presented to the parole board. ',
              src: '/bone-valley/E8f.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Crissie Carter Schofield\'s letter in support of her husband\'s parole. ',
              src: '/bone-valley/E8g.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Jeremy Scott\'s first letter to Gilbert King.',
              src: '/bone-valley/E8h.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Another letter from Jeremy Scott to Gilbert King. In this letter, Jeremy reveals some incriminating (and unsolicited) details about the murder of a taxi cab driver. ',
              src: '/bone-valley/E8i.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'A lengthy letter from Jeremy Scott, with a detailed account of the murder of Michelle Schofield.',
              src: '/bone-valley/E8j.jpg',
              flex: 6,
              height: '500px'
            },
          ],
          'S1E9': [
            {
              title: 'The entry sign for Martin Correctional Institution in Indiantown, Florida, where Gilbert and Kelsey arrived to interview Jeremy Scott.',
              src: '/bone-valley/E9a.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Photograph taken by the Polk County Sheriff\'s Office at the scene where Michelle Schofield\'s body was discovered on February 27, 1987. The trash photographed here was never collected by crime scene technicians and examined for blood or fingerprints.',
              src: '/bone-valley/E9b.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'A discarded pack of Marlboro cigarettes photographed by the Polk County Sheriff\'s Office at the scene where Michelle Schofield\'s body was discovered on February 27, 1987. Although photographed like evidence, this item was never collected and tested for physical evidence.',
              src: '/bone-valley/E9c.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Leo Schofield (left) poses with his band, The Watchers.',
              src: '/bone-valley/E9d.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'Leo Schofield (right) poses with his band, The Watchers.',
              src: '/bone-valley/E9e.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Leo Schofield (right) poses with his friend, cellmate, and songwriting partner Kevin Herrick.',
              src: '/bone-valley/E9g.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Leo and his daughter Ashley perform together alongside The Watchers at Hardee Correctional Institution.',
              src: '/bone-valley/E9f.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'A lion drawn by Leo Schofield, gifted to Judge Scott Cupp. ',
              src: '/bone-valley/E9h.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Gilbert King and Judge Scott Cupp stand in front of Leo\'s French Toast House in Alva, Florida.',
              src: '/bone-valley/E9i.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Gilbert stands with boxes of documents filled with Leo Schofield\'s case files in March, 2019. ',
              src: '/bone-valley/E9j.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Gilbert and Kelsey take a photo outside the Phosphate Musuem in Mulberry, Florida.',
              src: '/bone-valley/E9m.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Gilbert and Kelsey pose with Polk County Sheriff Grady Judd following his interview for Bone Valley.',
              src: '/bone-valley/E9k.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Gilbert is interviewed by ABC 20/20 for their episode, "Last Seen in Lakeland."',
              src: '/bone-valley/E9l.jpg',
              flex: 12,
              height: '500px'
            },
          ],
          'S1E10': [
            {
              title: 'Gilbert and Kelsey set off in the rental car to meet up with Judge Scott Cupp.',
              src: '/bone-valley/E10a.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'Gilbert and Kelsey are reunited with Judge Cupp.',
              src: '/bone-valley/E10b.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Kelsey interviews Judge Cupp about his expectations for the day while standing in a Publix Super Market parking lot.',
              src: '/bone-valley/E10c.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'Gilbert and Judge Cupp talk during the drive to Hardee Correctional Insititution.',
              src: '/bone-valley/E10d.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'A portrait of a lion -- created for and gifted to Judge Scott Cupp by Leo Schofield.',
              src: '/bone-valley/E10e.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'The entrance sign for Hardee Correctional Institution.',
              src: '/bone-valley/E10f.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Gilbert stands beside a phosphate mining dragline bucket outside the Phosphate Museum in Mulberry, Florida.',
              src: '/bone-valley/E10g.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Judge Cupp shows off the shirt he wore into the prison for his visitation with Leo. The shirt reads: "Keep Judge Scott Cupp Hendry County Judge."',
              src: '/bone-valley/E10h.jpg',
              flex: 6,
              height: '500px'
            },
            {
              titcle: 'A lighthouse, drawn by Leo Schofield, that now hangs in Gilbert\'s office. Leo titled the drawing, "The Way Home"',
              src: '/bone-valley/E10i.jpg',
              flex: 6,
              height: '500px'
            },
          ],
          'S1E11': [
            {title: 'Leo at Hardee Correctional Institution', src: '/bone-valley/E11a.jpg', flex: 6, height: '500px'},
            {
              title: 'Leo and his songwriting partner, Kevin Herrick, with their guitars.',
              src: '/bone-valley/E11b.jpg',
              flex: 6,
              height: '500px'
            },
          ],
          'S1E12': [
            {
              title: 'Pastor Tony Parker giving a tour of Noah Community Outreach.',
              src: '/bone-valley/E12a.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Crissie revs the Harley-Davidson motorcycle that\'s waiting for Leo in her garage.',
              src: '/bone-valley/E12b.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Scott Cupp and Gilbert visit with Florida Senator Jonathan Martin, center, in his Tallahassee office.',
              src: '/bone-valley/E12c.jpg',
              flex: 12,
              height: '500px'
            },
          ],
          'S1E13': [
            {
              title: 'Brittany Spangler, Kelsey Decker, and Kara Kornhaber working on Nothing but a Meatball.',
              src: '/bone-valley/E13a.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Gilbert holding one of Leo\'s grandsons at the pre-parole dinner.',
              src: '/bone-valley/E13b.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Crissie, Ashley, her two sons, and the family of Leo\'s bandmate, Kevin Herrick at the pre-parole dinner.',
              src: '/bone-valley/E13c.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Commissioners David Wyant and Richard Davison shortly before the Leo\'s parole hearing begins.',
              src: '/bone-valley/E13d.jpg',
              flex: 12,
              height: '500px'
            },
          ],
          'S1E15': [
            {
              title: 'Scott Cupp, Gilbert King, Dr. Regina B. Shearn, Leo Schofield, and Senator Jonathan Martin at a CTP banquet in July.',
              src: '/bone-valley/E15a.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'Everglades Correctional Institution',
              src: '/bone-valley/E15b.jpg',
              flex: 12,
              height: '500px'
            },
          ],
          'S1E17': [
            {
              title: 'Leo shares a long awaited hug with Kelsey',
              src: '/bone-valley/E17a.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Gilbert, Kara, and Kelsey share a moment with Leo after his release',
              src: '/bone-valley/E17b.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Crissie takes a selfie with Leo in the Tesla',
              src: '/bone-valley/E17c.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'Stopping for gas along the Tamiami Trail, Leo and Gilbert share a moment',
              src: '/bone-valley/E17d.jpg',
              flex: 6,
              height: '500px'
            },
            {
              title: 'In Tampa with Leo\'s adoptive father, Dave, and his sister Pam',
              src: '/bone-valley/E17e.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'In Tampa, Leo begins to play Sunset Micki, a song he wrote 37 years ago for his wife, Michelle ',
              src: '/bone-valley/E17f.jpg',
              flex: 12,
              height: '500px'
            },
            {
              title: 'At daybreak, Crissie and Leo pictured with Leo\'s sister Tammy, and his niece, Jasmine who flew in from Idaho for Leo\'s walkout',
              src: '/bone-valley/E17g.jpg',
              flex: 12,
              height: '375px'
            },
            {
              title: 'At 6:00 am in rain, Leo leaves prison property in the black Tesla',
              src: '/bone-valley/E17h.jpg',
              flex: 12,
              height: '375px'
            },
          ],
        }
      },
  }),
  methods: {
    getHeight: function(height) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return height - 150
        default : return height
      }
    },
    getVideoHeight: function(height) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return height - 110
        default : return height
      }
    },
    getVideoWidth: function(width) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return width - 195
        default : return width
      }
    },
  },
}
</script>
