<template>
    <section id="hero-alt" style="margin-bottom: -46px">
      <base-img
        :height="$vuetify.breakpoint.mdAndUp ? 350 : 225"
        :gradient="gradient"
        :src="src"
        flat
        max-width="100%"
        tile
      >
        <v-row
          v-if="title"
          align="end"
          class="ma-0 fill-height text-left"
        >
          <v-col cols="12" class="pl-3">
            <h1 class="mb-8 text-h3 text-md-h1 title--text-1">{{ title }}</h1>
          </v-col>
        </v-row>
      </base-img>
    </section>
</template>
<script>
//import {
//    HexToRGBA,
//    RGBAtoCSS,
//  } from 'vuetify/lib/util/colorUtils'

export default {
  name: 'Hero',
  props: { src: String, title: String, gradientTop: Number, gradientBottom: Number },
  computed: {
    gradient () {
      //const color = `${this.$vuetify.theme.themes.light.secondary}CC`
      //const overlay = RGBAtoCSS(HexToRGBA(color))
      //return `to top, ${overlay}, ${overlay}`
      //return 'to top, rgba(0,0,0,.6), rgba(0,0,0,' + this.gradientTop + ')'
      return 'to top, rgba(0,0,0,' + this.gradientBottom + '), rgba(0,0,0,' + this.gradientTop + ')'
    },
  },

}
</script>
<style lang="sass">
.title--text-1
  font-family: "Geogrotesque", sans-serif
  color: #EA1301
</style>
