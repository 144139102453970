<template>
  <div>
    <audio :src="audioLink" type="audio/mpeg" preload="auto"></audio>
    <v-card
      style="background: transparent"
    >
      <v-container class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col cols="2">
            <v-btn
              color="secondary"
              dark
              outlined
              class="float-right mr-4"
              @click="toggleAudio"
            >
              <v-icon large v-if="isPaused">
                mdi-play
              </v-icon>
              <v-icon large v-else>
                mdi-pause
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-slider
              v-model="currentTime"
              color="primary"
              track-color=accent
              thumb-label
              height="30"
              min="0"
              :max="duration"
              class="mt-1"
              @change="seekAudio"
              @mousedown="startSeek"
            >
              <template v-slot:prepend>
                <div class="time-display pt-1">{{ formatTime(currentTime) }}</div>
              </template>
              <template v-slot:append>
                <div class="time-display pt-1">{{ formatTime(duration) }}</div>
              </template>
              <template v-slot:thumb-label="{ value }">
                {{ formatTime(value) }}
              </template>
            </v-slider>
          </v-col>
          <v-col cols="3">
            <v-slider
              v-model="volume"
              color="primary"
              track-color=accent
              height="30"
              prepend-icon="mdi-volume-high"
              min="0"
              max="100"
              class="mt-1 ml-0 pl-0"
              @input="changeVolume"
            >
              <template v-slot:thumb-label="{ value }">
                {{ value }}
              </template>
              <template v-slot:prepend>
                <v-btn
                  color="black"
                  small
                  class="mr-n5 mt-n1 pr-0"
                  @click="toggleMuted"
                >
                  <v-icon v-if="isMuted">
                    mdi-volume-mute
                  </v-icon>
                  <v-icon v-else>
                    mdi-volume-high
                  </v-icon>
                </v-btn>
              </template>
            </v-slider>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Player',

  props: {
   audioLink: String,
  },

  data: () => ({
    audio: true,
    bpm: 15000,
    currentTime: 0,
    duration: 0,
    isPaused: false,
    volume: 0,
    isMuted: false,
    isSeeking: false,
  }),
  methods: {
    toggleAudio: function() {
      if(this.audio.paused) {
        this.audio.play()
      } else {
        this.audio.pause()
      }
      this.isPaused = this.audio.paused
    },
    formatVolume: function() {
      return Math.floor(this.volume / 100)
    },
    toggleMuted: function() {
      this.isMuted = !this.isMuted
      this.audio.muted = this.isMuted
    },
    changeVolume: function(value) {
      this.volume = value
      this.audio.volume = this.volume / 100
    },
    formatTime: function(secs) {
      const minutes = Math.floor(secs / 60)
      const seconds = Math.floor(secs % 60)
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
      return `${minutes}:${returnedSeconds}`
    },
    setAudioData: function() {
      this.duration = Math.floor(this.audio.duration)
      this.currentTime = Math.floor(this.audio.currentTime)
      this.isPaused = this.audio.paused
      this.volume = this.audio.volume * 100
    },
    startSeek: function() {
      this.isSeeking = true
    },
    seekAudio: function(seekTime) {
      this.audio.currentTime = seekTime
      this.isSeeking = false
    }

  },
  mounted: function () {
    this.audio = document.querySelector('audio')
    if (this.audio.readyState > 0) {
      this.setAudioData()
    } else {
      this.audio.addEventListener('loadedmetadata', () => {
        this.setAudioData()
      })
    }
    this.audio.addEventListener('timeupdate', () => {
      let currentTime = Math.floor(this.audio.currentTime)
      if (this.currentTime != currentTime && !this.audio.seeking && !this.isSeeking) {
        this.currentTime = Math.floor(this.audio.currentTime)
      }
    })
  }
}
</script>
<style>
div.time-display {
  font-size: 14px;
  color: #878787;
}
</style>
