<template>
    <section id="hero-alt">
      <base-img
        :height="computedHeight"
        :gradient="gradient"
        :src="src"
        flat
        max-width="100%"
        tile
      >
        <v-row
          v-if="title"
          align="end"
          class="ma-0 fill-height text-left"
        >
          <v-col cols="12" class="pl-3">
            <h1 class="text-h3 text-md-h1 title--text mb-12 mb-md-8">{{ title }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-n16 ml-3" cols="12">
            <div class="float-left"><v-icon color="accent" small class="mr-2">mdi-clock</v-icon>{{ duration | toHHMMSS }}</div><div class="ml-4 ml-md-16 float-left"><v-icon color="accent" small class="mr-2">mdi-calendar</v-icon>{{ published_at | moment}}</div>
            <a id='download-button' :href="audio_link" target="_blank">
              <span id="download-text">
                <v-icon color="accent">mdi-download</v-icon><br>
                Download
              </span>
              <span id="download-bg"></span>
            </a>
          </v-col>
        </v-row>
      </base-img>
    </section>
</template>
<script>
// import {
//     HexToRGBA,
//     RGBAtoCSS,
//   } from 'vuetify/lib/util/colorUtils'
import moment from 'moment'

export default {
  name: 'HeroAlt',
  props: { src: String, title: String, duration: String, published_at: String, audio_link: String, height: String, gradientTop: Number, gradientBottom: Number },
  filters: {
    moment: function (date) {
      return moment(date).format('DD MMMM, yyyy');
    },
    toHHMMSS: function (duration) {
      if(duration) {
        if (duration.includes(":")) {
          //already formatted
          return duration;
        } else {
          let seconds = duration % 60
          return parseInt(duration / 60) + ":" + ('0' + seconds).slice(-2);
        }
      }
    },
  },
  computed: {
    computedHeight () {
      if(this.height) {
        return this.height
      } else {
        return this.$vuetify.breakpoint.mdAndUp ? 600 : 400
      }
    },
    gradient () {
      //const color = `${this.$vuetify.theme.themes.light.secondary}CC`
      //const overlay = RGBAtoCSS(HexToRGBA(color))
      //return `to top, ${overlay}, ${overlay}`
      return 'to top, rgba(0,0,0,' + this.gradientBottom + '), rgba(0,0,0,' + this.gradientTop + ')'
    },
  },

}
</script>
<style lang="sass">
.title--text
  font-family: "Geogrotesque", sans-serif
  color: #EA1301

#download-button
    position: absolute
    cursor: pointer
    z-index: 10
    bottom: -8px
    right: 0
    text-align: center
    color: #ffffff
    font-weight: bold
    width: 125px
    height: 125px
    font-size: 13px
    overflow: hidden

#download-button span#download-text
  position: absolute
  bottom: 24px
  right: 9px
  display: block
  -ms-transform: rotate(-45deg)
  -webkit-transform: rotate(-45deg)
  transform: rotate(-45deg)
  z-index: 2

#download-button span#download-bg
  background: rgba(255,255, 255, 0.14)
  position: absolute
  display: block
  width: 300px
  height: 125px
  -ms-transform: rotate(-45deg)
  -webkit-transform: rotate(-45deg)
  transform: rotate(-45deg)
</style>
