<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="hover ? 12 : 2"
      :class="{ 'on-hover': hover }"
      @click="$router.push('/podcast/'+ slug + '/')"
    >
      <v-img
        :src="src"
        height="265px"
        gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.8)"
      >
        <div style="position: absolute; bottom: 0; left: 0; font-size: 1.3rem; width: 100%" >
          <p class="card-title">
            {{ title }}
          </p>
          <v-card-text class="bottom-bar"><span style="float: left"><v-icon color="accent" small class="clock">mdi-clock</v-icon>{{ duration | toHHMMSS }}</span><span style="float: right">{{ published_at | moment}}</span></v-card-text>
        </div>
      </v-img>
    </v-card>
  </v-hover>
</template>

<script>
import moment from 'moment'
export default {
  name: 'EpisodeCard',
  props: { src: String, title: String, published_at: String, duration: String, slug: String },
  filters: {
    moment: function (date) {
      return moment(date).format('DD MMMM, yyyy');
    },
    toHHMMSS: function (duration) {
      if (duration.includes(":")) {
        //already formatted
        return duration;
      } else {
        let seconds = duration % 60
        return parseInt(duration / 60) + ":" + ('0'+seconds).slice(-2);
      }
    },
  }
}
</script>
<style scoped>
.v-card {
  transition: opacity .2s ease-in-out;
}
.v-card:not(.on-hover) {
  opacity: 0.7;
 }
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
.bottom-bar {
  padding:0 0.7rem;
  color: #878787;
  background: black;
}
.card-title {
  text-align: left;
  margin: 0;
  padding: 0.7rem 0.7rem;
  font-family: Geogrotesque, sans-serif;
  line-height: 1.1em;
}
.clock {
  padding-right: 0.2em;
  padding-bottom: 0.2em;
}

</style>
