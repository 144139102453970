import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './plugins'
import VueGtag from "vue-gtag"

Vue.config.productionTip = false

Vue.use(VueGtag, {
  //config: { id: "UA-149288966-1" }
  config: { id: "G-8E8NYECXBP" }
}, router);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
