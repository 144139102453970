import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ripple from 'vuetify/lib/directives/ripple'
import tiktokicon from '@/components/TikTokIcon'


Vue.use(Vuetify, { directives: { ripple } });

const theme = {
  primary: '#EA1301',
  secondary: '#FDFDFD',
  accent: '#878787',
  info: '#00CAE3',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
}

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
       tiktokicon: {
         component: tiktokicon
       },
     }
  },
  theme: {
    dark: true,
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
