import Vue from 'vue'
import VueRouter from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Page', [
      route('NotFound', null, '/not-found'),
      route('Terms', null, '/terms'),
      route('Privacy', null, '/privacy'),
      route('About', null, '/about'),
      route('Awards', null, '/awards'),
      route('Store', null, '/store'),
      route('Episode', null, '/podcast/:slug'),
      route('Episodes', null, '/:slug'),
      route('Home', null, ''),
    ], '/'),
    layout('Admin', [
      route('List', null, '/admin/list'),
    ], '/admin'),
  ],
})

router.beforeEach((to, from, next) => {
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

export default router
